*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.1em;
}

.ql-toolbar.ql-snow {
  border: none;
  border-bottom: 1px solid grey;
}

.ql-container.ql-snow {
  border: none;
  min-height: 300px;
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px white;
  border-bottom-color: black;
  border-radius: 50%;
  content: "";
  height: 40px;
  position: absolute;
  top: 10%;
  left: 10%;
  transform: translate3d(-50%, -50%, 0);
  width: 40px;
  will-change: transform;
}

.spin {
  position: relative;
}

.line-height-normal {
  line-height: normal;
}
.line-height-tight {
  line-height: 1;
}
.line-height-comfortable {
  line-height: 2;
}
.line-height-spacious {
  line-height: 2.5;
}
.ql-snow .ql-picker.ql-lineheight {
  width: 58px;
}

.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="1"]::before {
  content: "1.0";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="1"]::before {
  content: "1.0" !important;
}

.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="1_5"]::before {
  content: "1.5";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="1_5"]::before {
  content: "1.5" !important;
}

.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="2"]::before {
  content: "2.0";
}

.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="2"]::before {
  content: "2.0" !important;
}

.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="2_5"]::before {
  content: "2.5";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="2_5"]::before {
  content: "2.5" !important;
}

.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="3"]::before {
  content: "3.0";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="3"]::before {
  content: "3.0" !important;
}

.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="3_5"]::before {
  content: "3.5";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="3_5"]::before {
  content: "3.5" !important;
}

.ql-lineHeight-1 {
  line-height: 1 !important;
}

.ql-lineHeight-1_5 {
  line-height: 1.5 !important;
}

.ql-lineHeight-2 {
  line-height: 2 !important;
}

.ql-lineHeight-2_5 {
  line-height: 2.5 !important;
}

.ql-lineHeight-3 {
  line-height: 3 !important;
}

.ql-lineHeight-3_5 {
  line-height: 3.5 !important;
}
